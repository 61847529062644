/**
 * @Copyright 2021 @DigiNet
 * @Author XUANLOC
 * @Create 5/4/2021
 * @Example
 */

import { makeStyles } from '@material-ui/core/styles';

export const useStyle = makeStyles((theme) => ({
    row: {
        background: '#F5F6F6',
        height: 'calc(100vh - 170px)',
        minHeight: 305,
        margin: 0,
        padding: '15px 0',
        [theme.breakpoints.down(992)]: {
            height: 'auto',
            '& > div[class*=col]:not(:first-child)': {
                marginTop: 15,
            },
        },
    },
    col: {
        height: '100%',
        '& > div > .react-swipeable-view-container': {
            height: '100%',
        },
    },
    tabContent: {
        height: '100%',
        '& > div.MuiTypography-root.MuiTypography-body1': {
            height: '100%',
            '& > div.MuiBox-root': {
                height: '100%',
            },
        },
    },
    container: {
        height: '100%',
        padding: 15,
        background: 'white',
        overflow: 'auto',
    },
    rowInfo1: {
        marginTop: 15,
        [theme.breakpoints.down(992)]: {
            '& > div[class*=col]:not(:first-child)': {
                marginTop: 15,
            },
        },
    },
    iconTransition: {
        display: 'inline-flex',
        transition: 'all 0.2s ease-in-out',
    },
    pointerCursor: {
        cursor: 'pointer',
    },
    dateRangeCont: {
        position: 'relative',
        width: '60%',
    },
    dateRangeInput: {
        display: 'flex',
        alignItems: 'center',
        fontSize: 16,
        fontWeight: 'normal',
        padding: '11px 15px',
        borderRadius: 4,
        width: 'calc(100% - 75px)',
    },
    spanLink: {
        color: '#0095FF',
        textDecoration: 'underline',
    },
    ddStatus: {
        width: 'fit-content',
    },
    flexCenter: {
        display: 'flex',
        alignItems: 'center',
    },
    divTextEditValue: {
        padding: 5,
        minWidth: 100,
        minHeight: 24,
        wordBreak: 'break-word',
        borderRadius: 4,
        whiteSpace: 'pre-line',
        '&:not(:focus-within):hover': {
            backgroundColor: '#ebecf0',
        },
        '&:focus-within': {
            boxShadow: '0 0 0 2px #4c9aff',
        },
    },
    pd0: {
        padding: '0 !important',
    },
    iconTextEdit: {
        display: 'inline-flex',
        alignItems: 'center',
        justifyContent: 'center',
        background: '#ebecf0',
        width: 32,
        height: 32,
        borderRadius: 4,
        boxShadow: '0px 2px 2px rgba(0, 0, 0, 0.25)',
        '&:not(:first-of-type)': {
            marginLeft: 5,
        },
    },
    divIconCont: {
        justifyContent: 'flex-end',
        position: 'absolute',
        zIndex: 2,
        marginTop: 5,
        right: 0,
    },
    divTextEditCont: {
        position: 'relative',
    },
    flexWrap: {
        flexWrap: 'wrap',
    },
    gap10: {
        gap: 10,
    },
    gap20: {
        gap: 20,
    },
    gap100: {
        gap: 100,
    },
    goalObjectName: {
        fontWeight: 'bold',
        fontSize: 18,
    },
    disabled: {
        pointerEvents: 'none',
        color: '#151A30 !important',
    },
    goalDescription: {
        color: '#555868',
        fontSize: 14,
        minHeight: 18,
    },
    tab1DetailCont: {
        marginTop: 30,
        overflow: 'auto',
    },
    tab1Detail: {
        display: 'grid',
        gridTemplateColumns: 'minmax(300px, 11fr) repeat(2, minmax(200px, 2fr)) minmax(100px, 1fr)',
        gap: 20,
        width: 'fit-content',
        borderBottom: '1px solid #D4D5D9',
    },
    headerDetail: {
        padding: '8px 0',
    },
    bodyDetail: {
        padding: '16px 0',
        '&:hover': {
            background: '#A2CFFF',
            '& #iconEdit': {
                visibility: 'visible !important',
                cursor: 'pointer',
            },
        },
    },
    flexRow: {
        display: 'flex',
        flexDirection: 'row',
    },
    flexColumn: {
        display: 'flex',
        flexDirection: 'column',
    },
    justifyCenter: {
        justifyContent: 'center',
    },
    justifyBetween: {
        justifyContent: 'space-between',
    },
    spanStatus: {
        display: 'inline-flex',
        borderRadius: '50%',
        color: 'white',
    },
    mgt20: {
        marginTop: 20,
    },
    iconHover: {
        '&:hover > path': {
            fill: '#111D5E',
        },
    },
    gap5: {
        gap: 5,
    },
    relativePos: {
        position: 'relative',
    },
    linkDisabled: {
        cursor: 'default',
        pointerEvents: 'none',
        color: '#7F828E',
    },
    linkDisabled2: {
        cursor: 'default !important',
        pointerEvents: 'none !important',
        color: '#151A30 !important',
    },
    tFDateRange: {
        '&:not(:focus-within):hover': {
            backgroundColor: '#DFEFFF',
        },
        '&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
            borderColor: '#111D5E',
        },
        '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: '#0095FF',
        },
    },
    alignCenter: {
        alignItems: 'center',
    },
    fullWidth: {
        width: '100%',
    },
    customInputLabel: {
        color: '#555868',
        fontWeight: 'bold !important',
        fontFamily: 'SF Pro Display Regular, sans-serif',
        fontSize: 12,
        marginBottom: 5,
    },
    divDetailInfo: {
        flex: '1 1 auto',
    },
    divDetailInfoChild: {
        display: 'grid',
        gridTemplateColumns: 'repeat(auto-fit, minmax(150px, 1fr))',
    },
    dBP: {
        boxShadow: 'none !important',
        '& .dx-texteditor-container .dx-texteditor-buttons-container': {
            display: 'none',
        },
        '& .dx-texteditor-input-container .dx-placeholder': {
            padding: '0 !important',
            color: '#0095FF !important',
            fontFamily: 'SF Pro Display Bold, sans-serif',
            fontWeight: 'bold',
            fontSize: '16px  !important',
        },
    },
    inputDBP: {
        padding: '0 !important',
    },
    coreFont: {
        color: '#0095FF',
        fontFamily: 'SF Pro Display Bold, sans-serif',
        fontWeight: 'bold',
        fontSize: 16,
    },
    ddGroupTarget: {
        width: 'fit-content',
        margin: '0px !important',
        '& > div': {
            border: 'none !important',
            minHeight: 'unset !important',
            '& > div:nth-child(1) > input': {
                padding: '0 !important',
                color: '#0095FF',
                fontWeight: 'bold',
                fontSize: 16,
            },
            '& > div:nth-child(2)': {
                display: 'none !important',
            },
            '&::after': {
                border: 'none !important',
            },
        },
        '&.disabled > div > div:nth-child(1) > input': {
            color: '#151A30 !important',
        },
    },
    spanUnit: {
        display: 'flex',
        alignItems: 'center',
        position: 'absolute',
        top: 0,
        right: 0,
        padding: '0 5px',
        background: 'white',
        userSelect: 'none',
        borderRadius: 4,
    },
    pdr25: {
        paddingRight: '25px !important',
    },
    maxWidth350: {
        maxWidth: 350,
    },
    divDatePicker: {
        display: 'flex',
        flexWrap: 'wrap',
        gap: 20,
        '& > *': {
            flex: '1 1 auto',
        },
    },
    grid: {
        '& div.dx-datagrid-rowsview tr:nth-last-child(2) > td.dx-datagrid-invalid div.dx-overlay-content.dx-resizable': {
            position: 'static',
        },
    },
}));
