/**
 * @Copyright 2021 @DigiNet
 * @Author XUANLOC
 * @Create 7/4/2021
 * @Example
 */

import Icon from 'diginet-core-ui/icons';
import PropTypes from 'prop-types';
import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';

const W39F3002TextEdit = forwardRef((props, ref) => {
    const { value, classes, disabled, unit, type, className, onSave } = props;

    const [isEdit, setIsEdit] = useState(false);

    const textEditContRef = useRef(null);
    const textEditRef = useRef(null);
    const caret = useRef(null);

    const originValue = value;

    useEffect(() => {
        const handleClickOutside = (e) => {
            if (textEditContRef.current && !textEditContRef.current.contains(e.target)) {
                const value = textEditRef.current.innerText;
                textEditRef.current.innerText = handleUnit(value, true);
                setIsEdit(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [textEditContRef]);

    const handleEdit = () => {
        setIsEdit(true);
        if (textEditRef.current) {
            setTimeout(() => {
                const value = textEditRef.current.innerText;
                textEditRef.current.innerText = handleUnit(value);
                textEditRef.current.focus();
            }, 0);
        }
    };

    const pasteAsPlainText = e => {
        e.preventDefault();
        let text = e.clipboardData.getData('text/plain');
        if (type === 'number') {
            if (text[0] && (text[0] === ',' || text[0] === '.')) {
                text = '0' + text;
            }
            if (text[text.length - 1] && (text[text.length - 1] === ',' || text[text.length - 1] === '.')) {
                text = text.slice(0, -1);
            }
            text = handleUnit(text);
            const regex = /^\d+((,|.)\d+)?$/;
            if (regex.test(text)) {
                document.execCommand('insertHTML', false, text);
            }
        } else {
            document.execCommand('insertHTML', false, text);
        }
    };

    const handleSave = () => {
        const value = textEditRef.current ? textEditRef.current.innerText : '';
        if (originValue !== value && onSave) onSave(value);
        setIsEdit(false);
    };

    const handleCancel = () => {
        if (textEditRef.current) {
            textEditRef.current.innerText = handleUnit(originValue, true);
        }
        setIsEdit(false);
    };

    const handleKeyDown = e => {
        const keyCode = e.keyCode || e.which;
        switch (keyCode) {
            case 9:
                if (e.preventDefault) e.preventDefault();
                handleCancel();
                break;
            case 18:
                if (e.preventDefault) e.preventDefault();
                handleCancel();
                break;
            case 27:
                if (e.preventDefault) e.preventDefault();
                handleCancel();
                break;
            default:
                if (type === 'number') {
                    if (caret.current === 0 && e.target.innerText.charCodeAt(0) === 45 && (keyCode === 109 || keyCode === 189)) {
                        if (e.preventDefault) e.preventDefault();
                    }
                    if (!e.ctrlKey && (keyCode >= 65 && keyCode <= 93)) {
                        if (e.preventDefault) e.preventDefault();
                    }
                    if (e.shiftKey) {
                        if (e.preventDefault) e.preventDefault();
                    }
                    if ((keyCode >= 19 && keyCode <= 36) || keyCode === 106 || keyCode === 107 || (keyCode >= 109 && keyCode < 188) || keyCode === 189 || (keyCode > 190 && keyCode <= 222)) {
                        if (e.preventDefault) e.preventDefault();
                    }
                    if ((keyCode === 188 || keyCode === 190) && (e.target.innerText.includes(',') || e.target.innerText.includes('.'))) {
                        if (e.preventDefault) e.preventDefault();
                    }
                }
                break;
        }
    };

    const getSelection = () => {
        if (isEdit) {
            caret.current = window.getSelection().anchorOffset ?? null;
        }
    };

    const handleUnit = (value, flag = false) => {
        let newValue = value;
        if (flag && value[value.length - 1] !== unit) {
            newValue = value + (unit ?? '');
        }
        if (!flag) {
            newValue = value.replace(unit, '');
        }
        return newValue;
    };

    useImperativeHandle(ref, () => ({
        revert: () => {
            textEditRef.current.innerText = originValue;
        }
    }));

    return (
        <div ref={textEditContRef} className={classes.divTextEditCont}>
            <div ref={textEditRef} className={className}
                contentEditable={isEdit} suppressContentEditableWarning={true} onPaste={e => pasteAsPlainText(e)}
                onDoubleClick={() => !disabled && !isEdit && handleEdit()}
                onClick={() => getSelection()}
                onKeyDown={e => {
                    getSelection();
                    handleKeyDown(e);
                }}>
                {`${value}${unit ?? ''}`}
            </div>
            {isEdit && <span className={`${classes.coreFont} ${classes.spanUnit}`}>{unit}</span>}
            {isEdit && <div className={`${classes.flexCenter} ${classes.divIconCont}`}>
                <span className={`${classes.iconTextEdit} ${classes.pointerCursor}`} onClick={() => handleSave()} >
                    <Icon name='Approval' style={{ cursor: 'pointer' }} />
                </span>
                <span className={`${classes.iconTextEdit} ${classes.pointerCursor}`} onClick={() => handleCancel()}>
                    <Icon name='Close' style={{ cursor: 'pointer' }} />
                </span>
            </div>}
        </div>
    );
});

W39F3002TextEdit.propTypes = {
    value: PropTypes.string,
    classes: PropTypes.object,
    disabled: PropTypes.bool,
    unit: PropTypes.string,
    type: PropTypes.string,
    className: PropTypes.string,
    onSave: PropTypes.func
};

W39F3002TextEdit.defaultProps = {
    value: '',
    classes: {},
    disabled: false,
    unit: '',
    type: 'string',
    className: '',
    onSave: null
};

export default W39F3002TextEdit;